new Vue({
  el: "#infoPage",
  data: function () {
    return {
      formInline: {
        type_val: "1",
        time_range: "",
        page: 1,
        page_size: 10,
        act_type: 3,
        sort_order: 1,
        name: ''
      },
      tableData: [],
      dateTime: "",
      count: 0,
      prevSort: "descending", // 保存之前的排序状态
      prevProp: "res_up_cnt", // 保存上一次为null时的prop
    };
  },
  mounted: function () {
    this.formInline.time_range =
      dayjs(new Date()).date() < 26
        ? dayjs(new Date(dayjs().subtract(1, "month"))).format("YYYYMM")
        : dayjs(new Date()).format("YYYYMM");
    this.showDetail();
  },
  methods: {
    sortMethodRes(before, after) {
      return Number(before.res_up_cnt) - Number(after.res_up_cnt);
    },
    sortMethodAct(before, after) {
      return Number(before.act_up_cnt) - Number(after.act_up_cnt);
    },
    sortMethodDown(before, after) {
      return Number(before.down_cnt) - Number(after.down_cnt);
    },
    sortMethodBrowse(before, after) {
      return Number(before.browse_cnt) - Number(after.browse_cnt);
    },
    sortMethodThumbs(before, after) {
      return Number(before.thumbs_up_cnt) - Number(after.thumbs_up_cnt);
    },

    pickerOptions: function () {
      var that = this;
      that.dateTime =
        dayjs(new Date()).date() < 26
          ? dayjs(new Date(dayjs().subtract(1, "month"))).format("YYYY-MM-DD")
          : dayjs(new Date()).format("YYYY-MM-DD");
      return {
        disabledDate(time) {
          return (
            time.getTime() < dayjs("2022-03-26").startOf("month").valueOf() ||
            time.getTime() > dayjs(that.dateTime).startOf("month").valueOf()
          );
        },
      };
    },
    changeType: function (num) {
      if (num == this.formInline.type_val) {
        return;
      } else {
        this.formInline.type_val = num;
        this.formInline.name = '';
        this.dateChange();
      }
    },
    dateChange: function () {
      // this.$refs.myTable.clearSort()
      this.formInline.page = 1;
      this.showDetail();
    },

    showDetail: function () {
      var that = this;
      $.ajax({
        type: "post",
        url: "/index/getUnitList",
        data: that.formInline,
        success: function (res) {
          if (res.code == 0) {
            that.tableData = res.data.list || [];
            that.count = parseInt(res.data.total) || 0;
          }
        },
      });
    },
    handleCurrentChange() {
      this.showDetail();
    },
    // 首页10条数据不请求接口 自定义排序
    sortChange({ column, prop, order }) {
      if (order !== null && this.prevProp !== prop) {
        let columns = this.$refs.myTable.columns.find(
          (x) => x.property === this.prevProp
        );
        columns.order = "";
      }
      if (order === null) {
        column.order =
          this.prevSort === "descending" ? "ascending" : "descending";
        this.prevProp = prop;
        this.$refs.myTable.sort(this.prevProp, column.order);
      }
      this.prevSort = column.order;
    },
    sortChangeAll({ column, prop, order }) {
      if (order !== null && this.prevProp !== prop) {
        let columns = this.$refs.myTable.columns.find(
          (x) => x.property === this.prevProp
        );
        columns.order = "";
      }
      if (order === null) {
        column.order =
          this.prevSort === "descending" ? "ascending" : "descending";
        this.prevProp = prop;
      }
      this.prevSort = column.order;

      switch (prop) {
        case 'browse_cnt':  // 浏览量
          this.formInline.act_type = 1;
          break;
        case 'res_up_cnt':  // 上传资源量
          this.formInline.act_type = 3;
          break;
        case 'down_cnt':  // 资源下载量
          this.formInline.act_type = 4;
          break;
        case 'thumbs_up_cnt':  // 点赞量
          this.formInline.act_type = 6;
          break;
        case 'act_up_cnt':  // 活动发布量
          this.formInline.act_type = 7;
          break;
        default:
          this.formInline.act_type = 3;
          break;
      }
      if (this.prevSort=='ascending') {
        this.formInline.sort_order = 2;
      } else {
        this.formInline.sort_order = 1;
      }
      this.dateChange();

    },
  },
});
